<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="blogs !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >

        <div class="m-2">

          <!-- Table Top -->


        </div>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="blogs"
            bordered
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >
          <template #cell(type)="data">

            <span>{{ data.item.type === 1 ? 'بلاگ' : 'مجله' }}</span>

          </template>

          <!-- Column: delete -->

          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="setSelectedBlog(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>

            </div>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
            >
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        cancelTitle="انصراف"
        centered
        no-close-on-backdrop
        ok-title="ثبت"
        @ok="changePriority(selectedBlog,newPriority)"
    >
      <v-select
          v-model="newPriority"
          dir="ltr"
          label="value"
          :options="priorities"
          :reduce="value => value.value"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogCategoryGetAllRequest, BlogGetAllRequest, ChangeBlogPriorities} from "@/libs/Api/Blog";
import Helper from "@/libs/Helper";
import awardIcon from "vue-feather-icons/icons/AwardIcon";

export default {
  name: "categories-list",
  title: "دسته بندی های بلاگ",
  data() {
    return {
      blogs: null,
      priorities: [
        {
          value: 1
        },
        {
          value: 2
        },
        {
          value: 3
        },
        {
          value: 4
        },
        {
          value: 5
        },
        {
          value: 6
        },
        {
          value: 7
        },
        {
          value: 8
        },
        {
          value: 9
        },
        {
          value: 10
        },
        {
          value: 11
        },
        {
          value: 12
        },
      ],
      newPriority: 0,
      oldPriority: 0,
      blogPriority: {
        blogCategoryId: 0,
        items: [
          {
            objectId: 0,
            priority: 0
          }
        ]
      },
      totalCount: null,
      currentPage: 1,
      overlay: false,
      perPage: 10,
      selectedBlog: null,
      myTableColumns: [
        {key: 'blogId', label: 'شناسه'},
        {key: 'title', label: 'نام'},
        {key: 'priority', label: 'اولویت'},
        {key: 'operation', label: 'عملیات'},

      ],
      seourl: this.$route.params.categorySeourl
    }
  },
  async created() {
    await this.getBlogs()
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },

  methods: {
    async changePriority(blog,priority) {
      let _this = this;
      _this.overlay = true
      _this.blogPriority.items[0].objectId = blog.blogId
      _this.blogPriority.items[0].priority = priority
      _this.blogPriority.blogCategoryId = _this.$route.params.id
      let changeBlogPriorities = new ChangeBlogPriorities(_this)
      changeBlogPriorities.setParams(_this.blogPriority)
      await changeBlogPriorities.fetch(async function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات اعمال شد.`,
          },
        })
       await _this.getBlogs()
        let findedBlog = _this.blogs.find(e => e.priority === priority)
        if (findedBlog || findedBlog!==undefined) {
          for (let i = 1; i < 13; i++) {
            if(_this.blogs.find(e => e.priority === i)===undefined){
              _this.changeOlderBlogPriority(findedBlog,i)
            }
          }
        }
      }, function (error) {
        console.log(error)
      })
      _this.overlay = false

    },
    async changeOlderBlogPriority(blog,priority){
      let _this = this;
      _this.overlay = true
      _this.blogPriority.items[0].objectId = blog.blogId
      _this.blogPriority.items[0].priority = priority
      _this.blogPriority.blogCategoryId = _this.$route.params.id
      let changeBlogPriorities = new ChangeBlogPriorities(_this)
      changeBlogPriorities.setParams(_this.blogPriority)
      await changeBlogPriorities.fetch(async function (content) {

        await _this.getBlogs()
      }, function (error) {
        console.log(error)
      })
      _this.overlay = false

    },
    async getBlogs() {
      let _this = this;
      _this.overlay = true
      let data = {
        pageNumber: _this.currentPage,
        count: 20,
        categorySEOURL: _this.seourl,
      };

      let blogCategoryGetAllRequest = new BlogGetAllRequest(_this)
      blogCategoryGetAllRequest.setParams(data)
      await blogCategoryGetAllRequest.fetch(function (content) {
        _this.blogs = content.blogs.sort((a, b) => a.priority - b.priority);
        _this.totalCount = content.blogsCount
      }, function (error) {
        console.log(error)
      })
      _this.overlay = false

    },
    setSelectedBlog(blog) {
      this.selectedBlog = blog
    }
  },
}
</script>

<style scoped>

</style>
